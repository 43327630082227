import React from 'react';
import PropTypes from 'prop-types';
import User03Icon from '@untitled-ui/icons-react/build/esm/User03';
import {
    Box,
    Button,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Popover,
    SvgIcon,
    Typography
} from '@mui/material';
import {RouterLink} from 'src/omnia/components/elements/router-link';
import { useNavigate } from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {setUser} from "../../../../store/actions/service-actions";
import {setIsTransitioning} from "../../../../store/actions/account-actions";
import useOmniaApi from "../../../../hooks/use-omnia-api";

export const AccountPopover = (props) => {
    const {anchorEl, menuItems, onClose, open, ...other} = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.service.user);
    const { post } = useOmniaApi({autoError: false});
    const { t } = useTranslation();

    const handleLogout = async () => {
        dispatch(setIsTransitioning(true));
        setTimeout(() => {

            post('services/logout').catch(errors => console.log('Logout failed: ', errors));
            localStorage.removeItem('serviceToken');
            dispatch(setUser(null));
            const logoutUrl = "/on/login/" + user?.context?.id;
            navigate(logoutUrl);

            setTimeout(() => {
                dispatch(setIsTransitioning(false));
            }, 500);
        }, 500);

    };

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={!!open}
            slotProps={{
                paper: {
                    sx: {
                        width: 180,
                    }
                }
            }}
            {...other}
        >
            <Box sx={{p: 2}}>
                <Typography variant="h6">
                    {user?.name}
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {user?.company}
                </Typography>
            </Box>
            <Divider/>
            <Box sx={{p: 1}}>
                <ListItemButton
                    component={RouterLink}
                    href={'#'}
                    onClick={() => alert(t('common.coming_soon'))}
                    sx={{
                        borderRadius: 1,
                        px: 1,
                        py: 0.5
                    }}
                >
                    <ListItemIcon>
                        <SvgIcon fontSize="small">
                            <User03Icon/>
                        </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                        primary={(
                            <Typography variant="body1">
                                {t("layout.profile")}
                            </Typography>
                        )}
                    />
                </ListItemButton>

                {menuItems.map((item, index) => (
                    <ListItemButton
                        key={'menu-item-' + index}
                        component={RouterLink}
                        href={item?.path}
                        onClick={onClose}
                        sx={{
                            borderRadius: 1,
                            px: 1,
                            py: 0.5
                        }}
                    >
                        <ListItemIcon>
                            <SvgIcon fontSize="small">
                                {item?.icon}
                            </SvgIcon>
                        </ListItemIcon>
                        <ListItemText
                            primary={(
                                <Typography variant="body1">
                                    {t(item?.title)}
                                </Typography>
                            )}
                        />
                    </ListItemButton>
                ))}

            </Box>
            <Divider sx={{my: '0 !important'}}/>
            <Box
                sx={{
                    display: 'flex',
                    p: 1,
                    justifyContent: 'center'
                }}
            >
                <Button
                    fullWidth
                    color="inherit"
                    onClick={handleLogout}
                    size="small"
                >
                    {t("layout.logout")}
                </Button>
            </Box>
        </Popover>
    );
};

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    menuItems: PropTypes.array,
    open: PropTypes.bool
};
