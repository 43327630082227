import PropTypes from 'prop-types';
import {Box, Divider, Link, Stack} from '@mui/material';
import Logo from "../../../general/logo";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

export const TenantSwitch = (props) => {

    const service = useSelector(state => state.service.user?.context);
    const [logoColor, setLogoColor] = useState("light");

    useEffect(() => {
        if ((service?.palette_mode || 'light') === "light") {
            if ((service?.nav_color || 'discreet') === "evident") {
                setLogoColor('light');
            } else {
                setLogoColor('dark');
            }
        } else {
            setLogoColor('light');
        }
    }, [service]);

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                {...props}
            >
                <div>
                    <Box
                        style={{
                            maxWidth: '100%',
                            height: '100%',
                            padding: 0,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <Logo
                            isHeader
                            width={service?.layout === 'horizontal' ? 'auto' : '80%'}
                            height={service?.layout === 'horizontal' ? 35 : 'auto'}
                            color={logoColor}
                        />
                    </Box>
                </div>
                <Divider
                    sx={{
                        backgroundColor: '#2D3748',
                        borderColor: '#2D3748',
                    }}
                />
            </Stack>
        </>
    );
};

TenantSwitch.propTypes = {
    sx: PropTypes.object
};
