import React, {memo} from "react";
import PropTypes from 'prop-types';
import {HorizontalLayout} from './horizontal-layout';
import {VerticalLayout} from './vertical-layout';
import {useDispatch, useSelector} from "react-redux";
import useSocket from "../../../hooks/use-socket";
import {setUnreadServiceNotifications} from "../../../store/actions/service-actions";
import OverridingThemeProvider from "../../elements/theme-provider/overriding-theme-provider";
import {PlainLayout} from "./plain-layout";

// eslint-disable-next-line react/display-name
const ServiceSocketConnection = memo(() => {
    const dispatch = useDispatch();
    useSocket('service', (data) => {

        if (data?.kind === 'unread_notification_count') {
            dispatch(setUnreadServiceNotifications(data?.data || 0));
        }

    }, true);
    return null;
}, () => true);

export const ServiceLayout = ({sections, menuItems, actionButton, ...props}) => {

    const service = useSelector(state => state.service.user?.context || null);

    if (!service)
        return null;

    return (
        <>

            {/* TODO: does not seem to connect!? */}
            <ServiceSocketConnection service={service}/>

            {service.layout === 'horizontal' && (
                <HorizontalLayout
                    sections={sections}
                    menuItems={menuItems}
                    navColor={service.nav_color}
                    {...props}
                />
            )}
            {service.layout === 'vertical' && (
                <VerticalLayout
                    sections={sections}
                    menuItems={menuItems}
                    actionButton={actionButton}
                    navColor={service.nav_color}
                    {...props}
                />
            )}
            {service.layout === 'plain' && (
                <PlainLayout
                    sections={sections}
                    menuItems={menuItems}
                    navColor={service.nav_color}
                    {...props}
                />
            )}
        </>
    )

};

ServiceLayout.propTypes = {
    children: PropTypes.node,
    service: PropTypes.object,
    actionButton: PropTypes.object,
    sections: PropTypes.array,
    menuItems: PropTypes.array
};

ServiceLayout.defaultProps = {
    menuItems: [],
    sections: [],
    actionButton: null
}