import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import {Box, IconButton, Link, Stack, SvgIcon, useMediaQuery} from '@mui/material';
import {alpha} from '@mui/material/styles';
import {AccountButton} from '../account-button';
import {NotificationsButton} from '../notifications-button';
import {TeamButton} from "../team-button";
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../general/logo";
import React from "react";
import {useSelector} from "react-redux";

const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 0;

export const TopNav = (props) => {
    const {onMobileNavOpen, menuItems, ...other} = props;
    const service = useSelector(state => state.service.user?.context);
    const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <Box
            component="header"
            sx={{
                backdropFilter: 'blur(6px)',
                backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
                position: 'sticky',
                left: {
                    lg: `${SIDE_NAV_WIDTH}px`
                },
                top: 0,
                width: {
                    lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
                },
                zIndex: (theme) => theme.zIndex.appBar
            }}
            {...other}>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    minHeight: TOP_NAV_HEIGHT,
                    px: 2
                }}
            >
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <Box style={{
                        maxWidth: '100%',
                        height: '100%',
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <Logo
                            // isHeader={service?.layout === 'plain'}
                            width={'auto'}
                            height={50}
                            // color={logoColor}
                        />
                    </Box>
                    {/*<IconButton onClick={onMobileNavOpen}>*/}
                    {/*    <SvgIcon>*/}
                    {/*        <Menu01Icon/>*/}
                    {/*    </SvgIcon>*/}
                    {/*</IconButton>*/}
                </Stack>
                <Stack
                    alignItems="center"
                    direction="row"
                    spacing={2}
                >
                    <TeamButton/>
                    <NotificationsButton/>
                    <AccountButton menuItems={menuItems} />
                </Stack>
            </Stack>
        </Box>
    );
};

TopNav.propTypes = {
    onMobileNavOpen: PropTypes.func
};
