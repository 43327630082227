import PropTypes from 'prop-types';
import {
    Avatar,
    Box,
    List,
    ListItem,
    Button,
    ListItemAvatar,
    ListItemText,
    Popover,
    Stack,
    Typography
} from '@mui/material';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import usePagination from "../../../../hooks/use-pagination";
import UserIcon from '@untitled-ui/icons-react/build/esm/User03';
import OnlineIndicator from "../../../modules/core/data-analytics/dashboards/online-indicator";
import {useSelector} from "react-redux";

export const TeamPopover = (props) => {
    const {
        onClose,
        anchorEl,
        open = false,
        ...other
    } = props;

    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState(1);
    const user = useSelector(state => state.service.user)
    const { elements, isMore, reset } = usePagination({
        endpoint: 'service/team',
        page: currentPage,
        append: true,
        query: {ordering: 'first_name'},
        pageSize: 20
    });

    const loadMore = () => {
        setCurrentPage(prev => prev + 1);
    }

    useEffect(() => {
        if(open){
            setCurrentPage(1);
            reset();
        }
    }, [open]);

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={open}
            slotProps={{
                paper: {
                    sx: {
                        width: 300,
                    }
                }
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    px: 3,
                    py: 2
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {t("layout.team")}
                </Typography>
            </Stack>
            {(elements && elements.length === 0) ? (
                <Box sx={{p: 3}}>
                    <Typography variant="subtitle2">
                        {t("common.sst_no_results")}
                    </Typography>
                </Box>
            ) : (
                <List sx={{overscroll: 'auto', maxHeight: 500, p: 0.5}}>
                    {elements.map((member, index) => (
                        <ListItem
                            key={'team-member-' + index}
                            sx={{
                                borderRadius: '10px',
                                // cursor: 'pointer',
                                px: 1,
                                py: 1,
                                '&:hover': {
                                    backgroundColor: 'action.hover'
                                },
                            }}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                style={{width: 'calc(100% - 30px)', display: 'flex'}}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <UserIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{mt: 0}}
                                    primary={(
                                        <Typography variant="subtitle1" color="textPrimary" noWrap>
                                            {member.name}
                                        </Typography>
                                    )}
                                />
                            </Stack>
                            <OnlineIndicator size="small" status={member.status} />

                        </ListItem>
                    ))}
                    {isMore ? (
                        <ListItem
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                p: 2
                            }}
                        >
                            <Button
                                color="primary"
                                onClick={loadMore}
                                size="small"
                                variant="text"
                            >
                                {t("common.load_more")}
                            </Button>
                        </ListItem>
                    ) : (
                        <ListItem
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                py: 0.5,
                            }}
                        />
                    )}
                </List>
            )}
        </Popover>
    );
};

TeamPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool
};
