import User01Icon from '@untitled-ui/icons-react/build/esm/User03';
import {Avatar, Box, ButtonBase, SvgIcon, Tooltip} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {AccountPopover} from './account-popover';
import {useSelector} from "react-redux";
import {useCreateSrc} from "../../../../hooks/use-create-src";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

export const AccountButton = ({menuItems}) => {

    const user = useSelector(state => state.service.user);
    const popover = usePopover();
    const { t } = useTranslation();
    const createSource = useCreateSrc();

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={popover.handleOpen}
                ref={popover.anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'divider',
                    height: 40,
                    width: 40,
                    borderRadius: '50%'
                }}
            >
                <Tooltip title={t('common.my_account')}>
                    <Avatar
                        sx={{
                            height: 32,
                            width: 32
                        }}
                        src={createSource(user?.avatar || null)}
                    >
                        <SvgIcon>
                            <User01Icon/>
                        </SvgIcon>
                    </Avatar>
                </Tooltip>
            </Box>
            <AccountPopover
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                open={popover.open}
                menuItems={menuItems}
            />
        </>
    );
};

AccountButton.propTypes = {
    menuItems: PropTypes.array
}