import React from "react";
import {createTheme} from "../../../theme";
import {ThemeProvider, useTheme} from "@mui/system";
import PropTypes from "prop-types";

function OverridingThemeProvider({children, mode, theme}){

    const activeTheme = useTheme();
    const config = theme || activeTheme?.config;
    const muiTheme = createTheme({...config, ...{paletteMode: mode || 'dark'}});

    return (
        <ThemeProvider theme={muiTheme}>
            {children}
        </ThemeProvider>
    )
}

OverridingThemeProvider.propTypes = {
    children: PropTypes.node,
    mode: PropTypes.string,
    theme: PropTypes.object
}

export default OverridingThemeProvider;