import React, {useRef, useState} from "react";
import PropTypes from 'prop-types';
import MarkReadIcon from '@untitled-ui/icons-react/build/esm/NotificationBox';
import {
    Avatar,
    Box,
    IconButton,
    List,
    Chip,
    ListItem,
    Button,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Stack,
    SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import {
    ToggleLeft as UnreadIcon,
    ToggleRight as ReadIcon,
    Trash as RemoveIcon,
} from "react-feather";
import {useTheme} from "@mui/system";
import moment from "moment/moment";
import {MoreHoriz as MoreVertIcon} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import OnIcon from "../../../elements/icon";
import useServiceApi from "../../../../hooks/use-service-api";

function SingleNotification({notification, onClickCallback, index, onMarkAsRead, onMarkAsUnread, onRemove}) {

    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const {post, del} = useServiceApi();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const theme = useTheme();
    const optionsRef = useRef(null);

    const handleOpenMenu = (event) => {
        event.stopPropagation();
        setOpen(true);
    }

    const handleCloseMenu = () => {
        setOpen(false);
    }

    const handleMarkRead = () => {
        onMarkAsRead(notification);
        handleCloseMenu();
        post('service/notifications/' + notification.id + '/mark_as_read').catch(() => {
            onMarkAsUnread(notification);
            handleCloseMenu();
        })
    }

    const handleMarkUnread = () => {
        handleCloseMenu();
        onMarkAsUnread(notification);
        post('service/notifications/' + notification.id + '/mark_as_unread').catch(() => {
            handleCloseMenu();
            onMarkAsRead(notification);
        })
    }

    const handleClick = () => {
        handleCloseMenu();
        if (onClickCallback)
            onClickCallback();
        handleMarkRead();
        navigate(notification.link);
    }

    const handleRemoveNotification = () => {
        del('service/notifications', notification.id).then(() => {
            handleCloseMenu();
            onRemove(notification);
        })
    }

    return (
        <ListItem
            key={index}
            sx={{
                borderRadius: '10px',
                cursor: 'pointer',
                px: 2,
                py: 1,
                '&:hover': {
                    backgroundColor: 'action.hover'
                },
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <Stack
                direction="row"
                onClick={handleClick}
                alignItems="center"
                style={{width: 'calc(100% - 30px)', display: 'flex'}}
            >
                <ListItemAvatar>
                    <Avatar style={{
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.primary.contrastText
                    }}>
                        <SvgIcon fontSize="small">
                            <OnIcon iconName={notification?.icon || 'Bell01'} />
                        </SvgIcon>
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{mt: 0}}
                    primary={(
                        <Stack direction="column" spacing={0} alignItems="flex-start">
                            <Typography variant="overline" sx={{lineHeight: 1.1, fontSize: 10}} color="textSecondary"
                                        noWrap>
                                {moment(notification.created_at).fromNow()}
                            </Typography>
                            <Typography variant="h6" color="textPrimary" noWrap>
                                {notification.title}
                            </Typography>
                        </Stack>
                    )}
                    secondary={(
                        <Typography variant="body2" sx={{mt: 0.5, lineHeight: 1.2}} color="textSecondary">
                            {notification.body}
                        </Typography>
                    )}
                />
                {notification.is_read || hover ? null :
                    <Chip
                        color="primary"
                        size="small"
                        style={{
                            position: 'absolute',
                            height: 10,
                            width: 10,
                            right: 7,
                            top: '50%',
                            transform: 'translateY(-50%)'
                        }}
                    />
                }
            </Stack>
            {hover && (
                <div style={{position: 'absolute', right: 7, top: '50%', transform: 'translateY(-50%)'}}>
                    <IconButton
                        aria-controls={"notification-menu-" + notification.id}
                        ref={optionsRef}
                        aria-haspopup="true"
                        onClick={(event) => handleOpenMenu(event)} // pass the event object
                        size="large"
                    >
                        <MoreVertIcon/>
                    </IconButton>
                    <Menu
                        id={"notification-menu-" + notification.id}
                        keepMounted
                        anchorEl={optionsRef.current}
                        open={open}
                        onClose={handleCloseMenu}
                    >
                        {(notification.is_read) ? (
                            <MenuItem onClick={handleMarkUnread}>
                                <ListItemIcon>
                                    <ReadIcon fontSize="small"/>
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    {t("common.mark_unread")}
                                </Typography>
                            </MenuItem>
                        ) : (
                            <MenuItem onClick={handleMarkRead}>
                                <ListItemIcon>
                                    <UnreadIcon fontSize="small"/>
                                </ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    {t("common.mark_read")}
                                </Typography>
                            </MenuItem>
                        )}
                        <MenuItem onClick={handleRemoveNotification}>
                            <ListItemIcon>
                                <RemoveIcon fontSize="small"/>
                            </ListItemIcon>
                            <Typography variant="inherit" noWrap>
                                {t("common.remove_notification")}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </div>
            )}
        </ListItem>
    );
}

export const NotificationsPopover = (props) => {
    const {
        anchorEl,
        moreExists,
        notifications,
        onClose,
        onMarkAllAsRead,
        onMarkAsRead,
        onMarkAsUnread,
        onRemove,
        onLoadMore,
        open = false,
        ...other
    } = props;

    const {t} = useTranslation();
    const isEmpty = notifications.length === 0;

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            disableScrollLock
            onClose={onClose}
            open={open}
            slotProps={{
                paper: {
                    sx: {
                        width: 300,
                    }
                }
            }}
            {...other}
        >
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
                sx={{
                    px: 3,
                    py: 2
                }}
            >
                <Typography
                    color="inherit"
                    variant="h6"
                >
                    {t("common.notifications")}
                </Typography>
                <Box>
                    <Tooltip title={t("common.mark_all_read")} placement="left">
                        <IconButton
                            onClick={onMarkAllAsRead}
                            size="small"
                            color="inherit"
                        >
                            <SvgIcon>
                                <MarkReadIcon/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Stack>
            {isEmpty ? (
                <Box sx={{p: 3}}>
                    <Typography variant="subtitle2">
                        {t("common.no_notifications")}
                    </Typography>
                </Box>
            ) : (
                <List sx={{overscroll: 'auto', maxHeight: 500, p: 1}}>
                    {/*<Scrollbar sx={{maxHeight: 500, px: 1}}>*/}
                    {notifications.map((notification, i) => (
                        <SingleNotification
                            notification={notification}
                            key={"single_notif_" + i}
                            onMarkAsRead={onMarkAsRead}
                            onMarkAsUnread={onMarkAsUnread}
                            onRemove={onRemove}
                            onClickCallback={onClose}
                            index={i}
                        />
                    ))}
                    {moreExists ? (
                        <ListItem
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                p: 2
                            }}
                        >
                            <Button
                                color="primary"
                                onClick={onLoadMore}
                                size="small"
                                variant="text"
                            >
                                {t("common.load_more")}
                            </Button>
                        </ListItem>
                    ) : (
                        <ListItem
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                py: 0.5,
                            }}
                        />
                    )}
                    {/*</Scrollbar>*/}
                </List>
            )}
        </Popover>
    );
};

SingleNotification.propTypes = {
    notification: PropTypes.object,
    onClickCallback: PropTypes.func,
    index: PropTypes.number,
    onMarkAsRead: PropTypes.func,
    onMarkAsUnread: PropTypes.func,
    onRemove: PropTypes.func
}

NotificationsPopover.propTypes = {
    anchorEl: PropTypes.any,
    notifications: PropTypes.array.isRequired,
    onClose: PropTypes.func,
    onMarkAllAsRead: PropTypes.func,
    onLoadMore: PropTypes.func,
    open: PropTypes.bool,
    moreExists: PropTypes.bool,
    onMarkAsRead: PropTypes.func,
    onMarkAsUnread: PropTypes.func,
    onRemove: PropTypes.func,
};
